import { Carousel } from "react-responsive-carousel";
import blue from "../img/store/blue.JPG";
import blue2 from "../img/store/blue2.JPG";
import classic from "../img/store/classic.JPG";
import classic2 from "../img/store/classic2.JPG";
import gray from "../img/store/gray.jpg";
import hoodie from "../img/store/hoodie.JPG";
import hoodie2 from "../img/store/hoodie2.JPG";
import keychain from "../img/store/keychain.JPG";
import keychain2 from "../img/store/keychain2.JPG";
import long from "../img/store/long.JPG";
import long2 from "../img/store/long2.JPG";
import minimal from "../img/store/minimal.jpg";
import red from "../img/store/red.JPG";
import red2 from "../img/store/red2.JPG";
import retro from "../img/store/retro.jpg";
import sand from "../img/store/sand.JPG";
import sand2 from "../img/store/sand2.JPG";
import sand3 from "../img/store/sand3.JPG";
import sand4 from "../img/store/sand4.JPG";
import snake from "../img/store/snake.JPG";
import snake2 from "../img/store/snake2.JPG";
import snakeHat from "../img/store/snake-hat.JPG";
import snakeHat2 from "../img/store/snake-hat2.JPG";
import snakeHoodie from "../img/store/snake-hoodie.JPG";
import snakeHoodie2 from "../img/store/snake-hoodie2.JPG";
import snakeLong from "../img/store/snake-long.JPG";
import snakeLong2 from "../img/store/snake-long2.JPG";
import sunsetHat from "../img/store/sunset-hat.JPG";
import sunsetHat2 from "../img/store/sunset-hat2.JPG";
import { useRef } from "react";
import white from "../img/store/white.JPG";
import white2 from "../img/store/white2.JPG";

export const Products = [
  {
    name: "Retro White Tee",
    images: [retro],
    sizes: [
      {
        size: "S",
        price_data: { product: "prod_QrEdfUEbaZGdcL", unit_amount: 2000 },
      },
      {
        size: "M",
        price_data: { product: "prod_QrEeudQSs5lBBB", unit_amount: 2000 },
      },
      {
        size: "L",
        price_data: { product: "prod_QrEf2jU9qzo8aS", unit_amount: 2000 },
      },
      {
        size: "XL",
        price_data: { product: "prod_QrEfB1NLMQgrYC", unit_amount: 2000 },
      },
      {
        size: "2XL",
        price_data: { product: "prod_QrEf4gYfIQ4QAv", unit_amount: 2000 },
      },
    ],
  },
  {
    name: "Gray Tee",
    images: [gray],
    sizes: [
      {
        size: "S",
        price_data: { product: "prod_QrEmKv4PiRqF6u", unit_amount: 2000 },
      },
      {
        size: "M",
        price_data: { product: "prod_QrEnbobokfFS29", unit_amount: 2000 },
      },
      {
        size: "L",
        price_data: { product: "prod_QrEnC1BI6O64PO", unit_amount: 2000 },
      },
      {
        size: "XL",
        price_data: { product: "prod_QrEonmAJEQmq9J", unit_amount: 2000 },
      },
      {
        size: "2XL",
        price_data: { product: "prod_QrEokRBzEd6ElY", unit_amount: 2000 },
      },
    ],
  },
  {
    name: "Minimal Black Tee",
    images: [minimal],
    sizes: [
      {
        size: "S",
        price_data: { product: "prod_QrEsRhXC2R6p7e", unit_amount: 2000 },
      },
      {
        size: "M",
        price_data: { product: "prod_QrEsDUVSNGl7wg", unit_amount: 2000 },
      },
      {
        size: "L",
        price_data: { product: "prod_QrEtFGJL6vfO3t", unit_amount: 2000 },
      },
      {
        size: "XL",
        price_data: { product: "prod_QrEtujkz7hSSRA", unit_amount: 2000 },
      },
      {
        size: "2XL",
        price_data: { product: "prod_QrEub8cPJYxEWD", unit_amount: 2000 },
      },
    ],
  },
  {
    name: "Western Sand Tee",
    images: [sand, sand2, sand3, sand4],
    sizes: [
      {
        size: "S",
        price_data: { product: "prod_OqhzHL25UvmMnO", unit_amount: 2000 },
      },
      {
        size: "M",
        price_data: { product: "prod_Oqi0nt3d0IWSB3", unit_amount: 2000 },
      },
      {
        size: "L",
        price_data: { product: "prod_Oqi1R43jtmLnSJ", unit_amount: 2000 },
      },
      {
        size: "XL",
        price_data: { product: "prod_Oqi1dxrwJluDII", unit_amount: 2000 },
      },
      {
        size: "2XL",
        price_data: { product: "prod_Oqi3Rvk1TUIB7O", unit_amount: 2000 },
      },
    ],
  },
  {
    name: "Snake Hat",
    images: [snakeHat, snakeHat2],
    price_data: { product: "prod_Ph9AVBbU0juEWb", unit_amount: 2500 },
  },
  {
    name: "Sunset Hat",
    images: [sunsetHat, sunsetHat2],
    price_data: { product: "prod_Ph99bz8lmAlDOk", unit_amount: 2500 },
  },
  {
    name: "Keychain",
    images: [keychain, keychain2],
    price_data: { product: "prod_Ph98ZNNj1fbZyv", unit_amount: 300 },
  },
  {
    name: "Snake Long Sleeve",
    images: [snakeLong, snakeLong2],
    sizes: [
      {
        size: "S",
        price_data: { product: "prod_NFC2nhxNZR2QWB", unit_amount: 2700 },
      },
      {
        size: "M",
        price_data: { product: "prod_NFC2uygfAaGu9r", unit_amount: 2700 },
      },
      {
        size: "L",
        price_data: { product: "prod_NFC25r9LC64GvZ", unit_amount: 2700 },
      },
      {
        size: "XL",
        price_data: { product: "prod_NFC3V2MBShpC9j", unit_amount: 2700 },
      },
    ],
  },
  {
    name: "Classic Long Sleeve",
    images: [long, long2],
    sizes: [
      {
        size: "S",
        price_data: { product: "prod_NDkjJXF05EyA6i", unit_amount: 2700 },
      },
      {
        size: "M",
        price_data: { product: "prod_NDkk9ugi8Z8zfR", unit_amount: 2700 },
      },
      {
        size: "L",
        price_data: { product: "prod_NDkkEBf9vL7kPI", unit_amount: 2700 },
      },
      {
        size: "XL",
        price_data: { product: "prod_NDkkzJKerJ7tOA", unit_amount: 2700 },
      },
    ],
  },
  {
    name: "Snake Hoodie",
    images: [snakeHoodie, snakeHoodie2],
    sizes: [
      {
        size: "S",
        price_data: { product: "prod_OYYuX80hG2fudK", unit_amount: 4500 },
      },
      {
        size: "M",
        price_data: { product: "prod_NDkhGi4gR2Z5jq", unit_amount: 4500 },
      },
      {
        size: "L",
        price_data: { product: "prod_NDkigjXDgiq8XO", unit_amount: 4500 },
      },
      {
        size: "XL",
        price_data: { product: "prod_NDkiy8NIQvZXzy", unit_amount: 4500 },
      },
    ],
  },
  {
    name: "Classic Hoodie",
    images: [hoodie, hoodie2],
    sizes: [
      {
        size: "S",
        price_data: { product: "prod_NFC4ByJTFPjLqn", unit_amount: 4500 },
      },
      {
        size: "M",
        price_data: { product: "prod_NFC73UpekDmzD5", unit_amount: 4500 },
      },
      {
        size: "L",
        price_data: { product: "prod_NFC8LdZgyCq4NX", unit_amount: 4500 },
      },
      {
        size: "XL",
        price_data: { product: "prod_NFC8qnOZstfTN3", unit_amount: 4500 },
      },
    ],
  },
  {
    name: "Classic Tee",
    images: [classic, classic2],
    sizes: [
      {
        size: "S",
        price_data: { product: "prod_NDlCbZfzAwnb98", unit_amount: 2000 },
      },
      {
        size: "M",
        price_data: { product: "prod_NDlD0WThwC2rmC", unit_amount: 2000 },
      },
      {
        size: "L",
        price_data: { product: "prod_NDlDNHxF8XyP58", unit_amount: 2000 },
      },
      {
        size: "XL",
        price_data: { product: "prod_OYYqDS5A3pmg95", unit_amount: 2000 },
      },
      {
        size: "2XL",
        price_data: { product: "prod_OYYwQ39kH99OCr", unit_amount: 2000 },
      },
    ],
  },
  {
    name: "Snake Tee",
    images: [snake, snake2],
    sizes: [
      {
        size: "S",
        price_data: { product: "prod_NFByTWthWXOwLh", unit_amount: 2000 },
      },
      {
        size: "M",
        price_data: { product: "prod_NFC0dahJYM4Mhy", unit_amount: 2000 },
      },
      {
        size: "L",
        price_data: { product: "prod_NFC0zLFN3qQxbO", unit_amount: 2000 },
      },
      {
        size: "XL",
        price_data: { product: "prod_NFC07JPK2fRQjE", unit_amount: 2000 },
      },
    ],
  },
  {
    name: "Blue Tee",
    images: [blue, blue2],
    sizes: [
      {
        size: "S",
        price_data: { product: "prod_OYYkkr88mpC1gL", unit_amount: 2000 },
      },
      {
        size: "M",
        price_data: { product: "prod_OYYkIxxoPWyMH8", unit_amount: 2000 },
      },
      {
        size: "L",
        price_data: { product: "prod_OYYlESFzcCSX3L", unit_amount: 2000 },
      },
      {
        size: "XL",
        price_data: { product: "prod_OYYlAx66iFR0Mw", unit_amount: 2000 },
      },
      {
        size: "2XL",
        price_data: { product: "prod_OYYld3mUDApgNw", unit_amount: 2000 },
      },
    ],
  },
  {
    name: "Red Tee",
    images: [red, red2],
    sizes: [
      {
        size: "S",
        price_data: { product: "prod_OYYioO4xB6ilAl", unit_amount: 2000 },
      },
      {
        size: "M",
        price_data: { product: "prod_OYYhZI5NXiSOgr", unit_amount: 2000 },
      },
      {
        size: "L",
        price_data: { product: "prod_OYYh1cy9EBhwVe", unit_amount: 2000 },
      },
      {
        size: "XL",
        price_data: { product: "prod_OYYg8HzpOhA3GQ", unit_amount: 2000 },
      },
      {
        size: "2XL",
        price_data: { product: "prod_OYYhSJaI492zQV", unit_amount: 2000 },
      },
    ],
  },
  {
    name: "White Tee",
    images: [white, white2],
    sizes: [
      {
        size: "S",
        price_data: { product: "prod_NDlEUlWqRJqsuc", unit_amount: 2000 },
      },
      {
        size: "M",
        price_data: { product: "prod_NDlFom8iYq9bez", unit_amount: 2000 },
      },
      {
        size: "L",
        price_data: { product: "prod_NDlFbxBcyoIpec", unit_amount: 2000 },
      },
      {
        size: "XL",
        price_data: { product: "prod_NDlFLXmkoB7zXy", unit_amount: 2000 },
      },
    ],
  },
];

export const Product = ({
  product,
  products,
  index,
  setSelectedProduct,
  setTossRef,
  addItemsToCart,
}) => {
  const theseProducts = products.filter((a) => a.name === product.name);
  const totalStock = theseProducts.reduce((s, a) => s + a.stock, 0);
  const ref = useRef();
  const price = product.sizes
    ? product.sizes[0].price_data.unit_amount
    : product.price_data.unit_amount;

  const tossToCart = () => {
    ref.current.classList.add("send-to-cart");
    setTimeout(() => {
      ref.current.classList.remove("send-to-cart");
    }, 1000);
  };

  const handleClick = () => {
    if (theseProducts[0].size) {
      setSelectedProduct(product);
      setTossRef(ref);
      console.log("Set toss");
    } else {
      tossToCart();
      addItemsToCart({ product });
    }
  };
  return (
    <div
      style={{
        // border: "1px solid white",
        padding: "4px",
        borderRadius: "8px",
        margin: "16px 32px",
      }}
    >
      <div
        style={{
          // aspectRatio: 1,
          width: "25vw",
          minWidth: "250px",
          borderRadius: "8px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {theseProducts.length > 0 && totalStock === 0 ? (
          <div
            style={{
              position: "absolute",
              backgroundColor: "var(--accent-color-1)",
              flex: 1,
              zIndex: 1,
              padding: "8px",
            }}
          >
            <h2>Sold Out!</h2>
          </div>
        ) : null}
        <img
          style={{
            position: "fixed",
            top: 0,
            right: 0,
            backgroundColor: "var(--accent-color-1)",
            zIndex: 9999,
            height: "150px",
          }}
          ref={ref}
          src={product.images[0]}
          alt={product.name}
          className="cartImg"
        />
        <Carousel
          swipeable={false}
          autoPlay={true}
          showThumbs={false}
          showArrows={true}
          showIndicators={true}
          infiniteLoop={true}
          transitionTime={1500}
          interval={15500 + index * 200}
          showStatus={false}
          // animationHandler={"fade"}
        >
          {product.images.map((img) => {
            return (
              <img
                src={img}
                alt={product.name}
                key={product.name}
                // style={{
                //   aspectRatio: 1,
                //   backgroundImage: `url(${img})`,
                //   backgroundPosition: "center",
                //   backgroundSize: "cover",
                //   backgroundRepeat: "no-repeat",
                //   width: "25vw",
                //   minWidth: "250px",
                //   borderRadius: "8px",
                // }}
              />
            );
          })}
        </Carousel>
      </div>
      <h4 style={{ margin: 0, textAlign: "left" }}>{product.name}</h4>
      {theseProducts.length > 0 ? (
        totalStock > 0 ? (
          <>
            {totalStock < 10 ? (
              <h6 style={{ margin: "4px", color: "var(--accent-color-1)" }}>
                Less than 10 remaining!
              </h6>
            ) : null}
            <h4
              style={{
                margin: 0,
                textAlign: "left",
                color: "lightgray",
                fontWeight: 500,
              }}
            >
              ${price / 100}
            </h4>
            <button onClick={() => handleClick()}>
              {theseProducts[0].size ? "Select Sizes" : "Add to Cart"}
            </button>
          </>
        ) : (
          <></>
        )
      ) : (
        <h6 style={{ margin: "2px" }}>Checking Availability...</h6>
      )}
    </div>
  );
};
